import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import { SeoQuery } from '../../types/graphql-types'

const Seo: React.FC = () => {
  const { site }: SeoQuery = useStaticQuery(graphql`
    query Seo {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const { title, description } = site.siteMetadata

  return (
    <Helmet
      htmlAttributes={{ lang: 'ja' }}
      title={title}
      meta={[
        { name: 'description', content: description },

        // ogp (facebook)
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:image',
          content: 'https://rabbitinc.info/SiteImage.png'
        },
        { property: 'og:image:alt', content: 'RABBIT' },
        { property: 'og:url', content: 'https://rabbitinc.info' },
        { property: 'og:locale', content: 'ja_JP' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: title },

        // twitter
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        {
          name: 'twitter:image',
          content: 'https://rabbitinc.info/SiteImage.png'
        },
        { name: 'twitter:image:alt', content: 'RABBIT' },
        { name: 'twitter:url', content: 'https://rabbitinc.info' },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@rabbit__inc' }
      ]}
    >
      <link rel="icon" sizes="512x512" href="/SiteIconRounded.png" />
      <link rel="apple-touch-icon" href="/SiteIcon.png" />
    </Helmet>
  )
}

export default Seo
